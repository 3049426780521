import store from "@/stores/";

export default [
  {
    path: "enquiries",
    component: () => import("@/modules/lpb/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("lpb/enquiries/loadEnquiries", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-lpb-enquiries",
        component: () => import("@/modules/lpb/views/enquiries/Dashboard"),
      },
      {
        path: ":enquiryId",
        name: "module-lpb-enquiries-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lpb/enquiries/loadEnquiry", {
              appId: to.params.id,
              enquiryId: to.params.enquiryId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/lpb/views/enquiries/Individual"),
      },
    ],
  },
];
