import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  enquiries: [],
  enquiry: {},
};

// getters
const getters = {
  all: (state) => state.enquiries,
  get: (state) => state.enquiry,
};

// actions
const actions = {
  loadEnquiries({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lpb/enquiries`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadEnquiriesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadEnquiry({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lpb/enquiries/${data.enquiryId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadEnquirySuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteEnquiry({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lpb/enquiries/${data.enquiryId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteEnquiriesuccess", {
            enquiryId: data.enquiryId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveEnquiry({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lpb/enquiries/${data.enquiryId}`
      : `${getApiUrl(data.appId)}/module/lpb/enquiries`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveEnquirieSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadEnquiriesSuccess(state, data) {
    state.enquiries = data.enquiries;
  },

  loadEnquirySuccess(state, data) {
    state.enquiry = data.enquiry;
  },

  saveEnquirieSuccess(state, data) {
    if (data.isEditing) {
      //  const index = state.enquiries.findIndex((c) => c.id === data.enquiry.id);
      //  state.enquiries.splice(index, 1, data.enquiry);

      state.enquiry = data.enquiry;
    } else {
      state.enquiries.push(data.enquiry);
    }
  },

  deleteEnquiriesuccess(state, data) {
    const index = state.enquiries.findIndex((p) => p.id === data.enquiryId);
    state.enquiries.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
